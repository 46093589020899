import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/product/startup-program/startups-meta.jpg'
import tag from '../assets/images/tags/swarmia-for-startups-dark.svg'
import FAQStartup from '../components/FAQStartup'
import Layout from '../components/Layout'
import StartupCarousel from '../components/ProductCarousel/StartupCarousel'
import Testimonials from '../components/Testimonials'
import CTABlockStartups from '../components/blocks/CTABlockStartups'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlockStartups'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlockStartup from '../components/blocks/MosaicBlock'
import { PersonasBlockStartup } from '../components/blocks/PersonasBlockStartup'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Br from '../components/system/Br'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: {
        eq: "images/product/startup-program/hero-startup-program.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/product/data-platform/lead.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/startup-program-partners.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/pr-filters.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/issue-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    blurb1: file(
      relativePath: { eq: "images/product/data-platform/data-cloud.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    blurb2: file(
      relativePath: { eq: "images/product/data-platform/exports-api.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 551)
      }
    }
    mosaicRight1: file(
      relativePath: {
        eq: "images/mosaic/startup-program-partner-logos-example.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
  }
`
const StartupsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Swarmia for startups"
      variant="dark"
      isNew
      description="Get real-time visibility and spot bottlenecks. Apply to get 50% off, or sign up for free."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Your growing team ships faster with Swarmia</>}
        content={
          <>
            Get real-time visibility into your engineering organization. <Br />
            Apply to save&nbsp;50% on the first 12 months.
          </>
        }
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      />
      <LeadBlock
        heading="Boost developer productivity with reliable data and visualizations"
        content="Combine healthy, team-level engineering metrics with automated feedback loops and developer experience surveys."
      />
      <Block
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        maxWidth={1220}
      >
        <StartupCarousel />
      </Block>
      <LeadBlock
        heading={
          <>
            Powerful insights
            <br />
            at every level
          </>
        }
        content={
          <>
            Swarmia serves the whole engineering organization
            <Br />
            from developers to the leadership team.
          </>
        }
      />
      <PersonasBlockStartup />
      <Box />
      <LeadBlock
        heading={
          <>
            Apply to get
            <Br /> 50%&nbsp;off for&nbsp;12&nbsp;months
          </>
        }
      />
      <MosaicBlockStartup
        title="Who can apply"
        content={
          <Stack>
            <p>
              Swarmia Startup Program is designed to help the best software
              teams get better starting from day one.
              <br />
              <br />
              Startups with the following criteria are eligible for the program:
            </p>
            <Stack space={16}>
              <Box>
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="normal" color="darkBlue">
                Your company is new to Swarmia
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="normal" color="darkBlue">
                Your startup has 50 developers or less
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="normal" color="darkBlue">
                Your startup is affiliated with a
                <br />{' '}
                <a href="#investor-members">
                  Swarmia Startup Program partner →
                </a>
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
      />
      <Box>
        <CTABlockStartups
          title={
            <>
              The best teams get better all the time.
              <br />
              Start using Swarmia today.
            </>
          }
        />
      </Box>
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQStartup />
      </Box>
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      >
        <Testimonials />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/ship-software-10x-faster/',
            '/blog/how-we-use-swarmia-at-swarmia/',
            '/blog/measuring-software-development-productivity/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default StartupsPage
